<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M267.3,75.335c0,15.526-9.15,28.918-22.357,35.071c-4.957,2.306-10.482,3.601-16.315,3.601
	c-21.346,0-38.66-17.314-38.66-38.672c0-21.346,17.314-38.66,38.66-38.66c5.833,0,11.358,1.295,16.315,3.601
	C258.15,46.429,267.3,59.81,267.3,75.335z"
    />
    <path
      style="fill:#F6C454;"
      d="M267.3,75.335c0,15.526-9.15,28.918-22.357,35.071c-13.207-6.154-22.357-19.546-22.357-35.071
	s9.15-28.906,22.357-35.059C258.15,46.429,267.3,59.81,267.3,75.335z"
    />
    <circle style="fill:#FED159;" cx="283.371" cy="75.346" r="38.669" />
    <path
      style="fill:#F64B4A;"
      d="M422.51,475.325H89.527c-37.111,0-67.196-30.085-67.196-67.196v-35.774
	c0-129.061,104.625-233.686,233.686-233.686l0,0c129.061,0,233.686,104.625,233.686,233.686v35.776
	C489.705,445.241,459.621,475.325,422.51,475.325z"
    />
    <path
      style="fill:#E84242;"
      d="M86.458,408.131v-35.776c0-129.061,75.915-233.686,169.561-233.686l0,0
	c-129.061,0-233.686,104.625-233.686,233.686v35.776c0,37.111,30.084,67.194,67.196,67.194h45.687
	C108.286,475.325,86.458,445.241,86.458,408.131z"
    />
    <path
      style="fill:#FED159;"
      d="M510.447,279.571c6.018,16.525-6.203,34.011-23.8,34.011h-4.402
	c-10.593-40.917-32.001-77.493-60.98-106.472c-42.298-42.286-100.713-68.441-165.245-68.441
	c-108.766,0-200.169,74.299-226.226,174.913h-4.415c-17.511,0-29.83-17.375-23.85-33.838
	c13.898-38.302,36.527-73.275,66.838-102.464c50.782-48.908,119.001-76.074,189.514-75.594
	c71.61,0.469,138.855,28.585,189.539,79.268C475.807,209.342,497.117,242.922,510.447,279.571z"
    />
    <circle style="fill:#FFE4A9;" cx="297.108" cy="63.544" r="14.554" />
    <polygon
      style="fill:#E84242;"
      points="313.831,274.686 313.831,250.176 268.273,250.176 268.273,218.605 243.765,218.605 
	243.765,250.176 198.207,250.176 198.207,329.937 289.322,329.937 289.322,360.678 198.207,360.678 198.207,385.188 
	243.765,385.188 243.765,416.76 268.273,416.76 268.273,385.188 313.831,385.188 313.831,305.427 222.716,305.427 222.716,274.686 
	"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
